import { graphql } from "gatsby";
import React from "react";
import { InfoPage } from "../components/InfoPage";
import { Layout } from "../components/Layout";
import Metadata from "../components/Metadata";
import { RichText } from "../components/RichText";

const About = ({ data }) => {
    const { image, title, content } = data.contentfulAboutUsPage;
    return (
        <Layout>
            <Metadata title="About Us" desc="" />
            <InfoPage
                title={title}
                image={{
                    src: image.resize.src,
                }}
            >
                <RichText>{content}</RichText>
            </InfoPage>
        </Layout>
    );
};

export default About;

export const query = graphql`
    query AboutPageQuery {
        contentfulAboutUsPage {
            id
            title
            # description {
            #     description
            # }
            image {
                resize(width: 1000) {
                    src
                }
            }
            content {
                raw
            }
        }
    }
`;
